import react, { useState, Fragment, useEffect } from 'react';
import {
  Text,
  Container,
  Divider,
  Stack,
  UnstyledButton,
  CloseButton,
  Skeleton,
} from '@mantine/core';
import { TextInput } from '@mantine/core';
import { IconSearch } from '@tabler/icons';
import { Link } from 'react-router-dom';
import { IconArrowUp, IconArrowLeft } from '@tabler/icons';
import { useWindowScroll } from '@mantine/hooks';
import { Affix, Button, Transition } from '@mantine/core';
import ReactGA from "react-ga";

const loadingSkeletons = (
  <>
    <Skeleton height={50} />
    <Skeleton height={50} />
    <Skeleton height={50} />
    <Skeleton height={50} />
    <Skeleton height={50} />
    <Skeleton height={50} />
    <Skeleton height={50} />
  </>
);

export default function ExamsByTopic() {
  const [topics, setTopics] = useState([]);
  const [_topics, setFilteredTopics] = useState([]);
  const [scroll, scrollTo] = useWindowScroll();
  const [isLoading, setIsLoading] = useState(true);
  const [searchFieldValue, setSearchFieldValue] = useState('');
  ReactGA.pageview(window.location.pathname + window.location.search);

  const filterTopics = (event) => {
    const searchKeyWord = event.target.value.toLowerCase();
    if (searchKeyWord) {
      setTopics(
        topics.filter(({ label }) =>
          label
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .includes(searchKeyWord)
        )
      );
    } else {
      setTopics(_topics);
    }
  };

  const clearSearchField = () => {
    setSearchFieldValue('');
    setTopics(_topics);
  };

  useEffect(() => {
    fetch('https://wei-backend.herokuapp.com/topics/')
      .then((response) => response.json())
      .then((response) => {
        setTopics(response);
        setFilteredTopics(response);
        setIsLoading(false);
      });
  }, []);

  return (
    <Container sx={{ backgroundColor: '#25262b' }}>
      {isLoading && <Skeleton height={35} radius={0} mt="xl" />}
      {!isLoading && (
        <>
          <Text mt="xl" color={'white'} size="xl" fw={700}>
            <IconArrowLeft
              style={{ verticalAlign: 'middle', marginRight: '10px' }}
              onClick={() => {
                window.history.back();
              }}
            />{' '}
            Tópicos
          </Text>
        </>
      )}
      {!isLoading && (
        <TextInput
          icon={<IconSearch size={18} stroke={1.5} />}
          radius="md"
          size="md"
          mt="xl"
          value={searchFieldValue}
          rightSection={<CloseButton onClick={clearSearchField} />}
          placeholder="Pesquisar tópico"
          onChange={(event) => {
            setSearchFieldValue(event.target.value != null ? event.target.value.toLowerCase() : "");
            filterTopics(event);
          }}
        />
      )}
      <Stack mt="xl">
        {!topics && !isLoading && topics.length === 0 && (
          <Text color={'white'} align="center" size="xl">
            Topico não encontrado
          </Text>
        )}
        {isLoading && loadingSkeletons}
        {topics &&
          !isLoading &&
          topics.map(({ label, slug }) => (
            <Fragment key={slug}>
              <UnstyledButton component={Link} to={`/topico/${slug}`} mt="xs">
                <Text size="lg">{label}</Text>
              </UnstyledButton>
              <Divider />
            </Fragment>
          ))}
      </Stack>
      <Affix position={{ bottom: 20, right: 20 }}>
        <Transition transition="slide-up" mounted={scroll.y > 0}>
          {(transitionStyles) => (
            <Button
              leftIcon={<IconArrowUp size={16} />}
              style={transitionStyles}
              sx={(theme) => ({
                background: 'rgba(111,137,244,1)',
                color: '#f5f5f5',
              })}
              onClick={() => scrollTo({ y: 0 })}
            >
              Voltar ao topo
            </Button>
          )}
        </Transition>
      </Affix>
    </Container>
  );
}

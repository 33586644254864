import { createRoot } from 'react-dom/client';
import { MantineProvider } from '@mantine/core';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import SelectExam from './SelectExam';
import './styles.css';
import Exam from './Exam';
import App from './App';
import Pricing from './Pricing';
import Topics from './Topics';
import Edics from './Editcs';
import Results from "./Results"
import NotFound from "./NotFound"
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);
import ReactGA from 'react-ga';
ReactGA.initialize('G-KFBT6KJF6L');
ReactGA.pageview(window.location.pathname + window.location.search);
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <NotFound/>
  },
  {
    path: '/escolher-exame',
    element: <SelectExam />,
    errorElement: <NotFound/>
  },
  {
    path: '/exame/:exam_id',
    element: <Exam />,
    errorElement: <NotFound/>
  },
  {
    path: '/precario',
    element: <Pricing />,
    errorElement: <NotFound/>
  },
  {
    path: '/topicos',
    element: <Topics />,
    errorElement: <NotFound/>
  },
  {
    path: '/editais',
    element: <Edics />,
    errorElement: <NotFound/>
  },
  {
    path: '/topico/:topic_slug',
    element: <Exam />,
    errorElement: <NotFound/>
  },
  {
    path: '/resultado',
    element: <Results />,
    errorElement: <NotFound/>
  },
  {
    path: '/correcao/:correction_slug',
    element: <Exam />,
    errorElement: <NotFound/>
  },
  {
    path: '/resolucao/:correction_slug',
    element: <Exam />,
    errorElement: <NotFound/>
  },
  {
    path: "*",
    element: <NotFound />,
    errorElement: <NotFound/>
  },
  {
    path: '/404',
    element: <NotFound />,
    errorElement: <NotFound/>
  }
]);

Sentry.init({
  dsn: "https://294db35ae31d4863ba27dd683ffb40db@o691949.ingest.sentry.io/4504470945988608",
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
});

root.render(
  <MantineProvider
    theme={{
      colorScheme: 'dark',
    }}
  >
    <RouterProvider router={router} />
  </MantineProvider>
);

import react from 'react';
import { Center, Title } from '@mantine/core';
import AnimatedNumber from 'react-animated-number';

export default function Score({ score }) {
  return (
    <Center>
      <Title align="center" color="white" sx={{fontSize: '5em'}} order={2}>
        <AnimatedNumber value={score} duration={2000} stepPrecision={0} />
        <span style={{ fontSize: '25pt', color: '#ffffff85' }}>/20</span>
      </Title>
    </Center>
  );
}

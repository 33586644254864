import react, { useState, Fragment, useEffect } from 'react';
import {
  Text,
  Container,
  Divider,
  Stack,
  UnstyledButton,
  CloseButton,
  Skeleton,
} from '@mantine/core';
import { TextInput } from '@mantine/core';
import { IconSearch } from '@tabler/icons';
import { Link } from 'react-router-dom';
import { IconArrowUp } from '@tabler/icons';
import { useWindowScroll } from '@mantine/hooks';
import { Affix, Button, Transition } from '@mantine/core';

const loadingSkeletons = (
  <>
    <Skeleton height={50} />
    <Skeleton height={50} />
    <Skeleton height={50} />
    <Skeleton height={50} />
    <Skeleton height={50} />
    <Skeleton height={50} />
    <Skeleton height={50} />
  </>
);

export default function ExamsByTopic() {
  const [topics, setTopics] = useState([]);
  const [_topics, setFilteredTopics] = useState([]);
  const [scroll, scrollTo] = useWindowScroll();
  const [isLoading, setIsLoading] = useState(true);
  const [searchFieldValue, setSearchFieldValue] = useState('');
  const filterTopics = (event) => {
    setTopics(
      topics.filter(({ label }) =>
        label
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .includes(event.target.value)
      )
    );
  };

  const clearSearchField = () => {
    setSearchFieldValue('');
    setTopics(_topics);
  };

  useEffect(() => {
    setTimeout(() => {
        setIsLoading(false)
    }, 1000)
  }, [])

  return (
    <Container sx={{ backgroundColor: '#25262b' }}>
      {isLoading && <Skeleton height={35} radius={0} mt="xl" />}
      {!isLoading && (
        <Text mt="xl" color={'white'} size="xl" fw={700}>
          Editais
        </Text>
      )}
      {!isLoading && (
        <TextInput
          icon={<IconSearch size={18} stroke={1.5} />}
          radius="md"
          size="md"
          mt="xl"
          value={searchFieldValue}
          rightSection={<CloseButton onClick={clearSearchField} />}
          placeholder="Pesquisar curso"
          onChange={(event) => {
            setSearchFieldValue(event.target.value);
            filterTopics(event);
          }}
        />
      )}
      <Stack mt="xl">
        {!topics && !isLoading && topics.length === 0 && (
          <Text color={'white'} align="center" size="xl">
            Topico não encontrado
          </Text>
        )}
        {isLoading && loadingSkeletons}
        {topics &&
          !isLoading &&
          topics.map(({ label, slug }) => (
            <Fragment key={slug}>
              <UnstyledButton component={Link} to={`/topico/${slug}`} mt="xs">
                <Text size="lg">{label}</Text>
              </UnstyledButton>
              <Divider />
            </Fragment>
          ))}
      </Stack>
      <Affix position={{ bottom: 20, right: 20 }}>
        <Transition transition="slide-up" mounted={scroll.y > 0}>
          {(transitionStyles) => (
            <Button
              leftIcon={<IconArrowUp size={16} />}
              style={transitionStyles}
              onClick={() => scrollTo({ y: 0 })}
            >
              Voltar ao topo
            </Button>
          )}
        </Transition>
      </Affix>
    </Container>
  );
}
